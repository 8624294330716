



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { INavData } from '@/interfaces/nav-data.interface';
import NavBarMenuItem from '@/components/nav-bar-menu-item.vue';
import NavBarBtn from '@/components/nav-bar-btn.vue';

@Component({
  components: {
    NavBarBtn,
    NavBarMenuItem,
  },
})
export default class NavBarMenu extends Vue {
  @Prop({ required: true })
  readonly item!: INavData;

  @Prop()
  readonly disabled!: boolean;
}
