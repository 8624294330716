

































import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';
import ContactModel from '@/models/crm/contact.model';
import CardDataList from '../card-data-list.vue';
import ClientModel from '@/models/crm/client.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    CardDataList,
  },
})
export default class CrmExistingContactsView extends Vue {
  @VModel()
  existingContacts!: ContactModel[];

  @Prop({ required: true, default: false })
  isFromProspectForm!: boolean;

  @Prop({ required: true })
  client!: ClientModel;

  @Prop()
  title!: string;

  @Prop()
  contactClientType!: string;

  @Prop()
  contactNumberType!: string;

  existingContactsDataListColumns: ISimpleTableColumn[] = [
    {
      name: `${this.$t('crm.view.existingContacts.clientType')}`,
      prop: 'tipoCliente',
    },
    {
      name: `${this.$t('crm.view.existingContacts.cnpj')}`,
      prop: 'cnpjFormatado',
    },
    {
      name: `${this.$t('crm.view.existingContacts.legalName')}`,
      prop: 'nomeCliente',
    },
    {
      name: `${this.$t('crm.view.existingContacts.name')}`,
      prop: 'nome',
    },
  ];

  get clientType(): string {
    if (this.contactClientType) {
      return this.contactClientType.toLowerCase() === ClientTypeEnum.Client ? 'cliente' : 'prospect';
    }
    return this.client.type === ClientTypeEnum.Client ? 'cliente' : 'prospect';
  }

  get contactType(): string {
    const isPlural = this.existingContacts.length > 1;
    if (this.isFromProspectForm) {
      return isPlural ? 'cadastros' : 'cadastro';
    }

    return isPlural ? 'contatos' : 'contato';
  }

  get pluralHaving(): string {
    const isPlural = this.existingContacts.length > 1;
    return isPlural ? 'possuem' : 'possui';
  }

  onSave(): void {
    this.$emit('complete');
  }

  onCancelSave(): void {
    this.$emit('cancel');
  }

  get clientName(): string {
    return this.client ? this.client.nome : '';
  }
}
