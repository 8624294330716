









































import { Vue, Component, Prop } from 'vue-property-decorator';
import ContactModel from '@/models/crm/contact.model';
import CardDataList from '../card-data-list.vue';

@Component({
  components: {
    CardDataList,
  },
})
export default class CrmChatSelectClientProspectForm extends Vue {
  @Prop()
  existingContacts!: ContactModel[];

  contatoSelecionado: number | null = null;

  onSave(): void {
    if (this.contatoSelecionado !== null) {
      this.$emit('complete', this.existingContacts[this.contatoSelecionado]);
    } else this.$emit('complete', null);
  }

  onCancelSave(): void {
    this.$emit('cancel');
  }
}
