




























































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';
import ContactService from '@/services/crm/contact.service';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ContactModel from '@/models/crm/contact.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import DatePickerField from '@/components/date-picker-field.vue';
import ClientModel from '@/models/crm/client.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ClientService from '@/services/crm/client.service';
import ProspectModel from '@/models/crm/prospect.model';
import ProspectService from '@/services/crm/prospect.service';
import ContentDialog from '@/components/content-dialog.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import CrmChatProspectForm from '@/components/crm/chat-prospect-form.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import CallCenterCallService from '@/services/crm/call-center-call.service';

interface ICallCenterContactFormModel {
  callType: ConversationTypeEnum;
  client: ClientModel | null;
  prospect: ProspectModel | null;
  contact: ContactModel;
}

@Component({
  components: {
    Field,
    DatePickerField,
    ContentDialog,
    CrmChatProspectForm,
  },
})
export default class CrmCallCenterContactForm extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CallCenterCallService)
  private callCenterCallService!: CallCenterCallService;

  @Prop({ required: true })
  call!: CallCenterCallModel;

  formIsValid = true;

  settings: SettingsModel | null = null;

  model: ICallCenterContactFormModel = {
    callType: ConversationTypeEnum.Client,
    client: null,
    prospect: null,
    contact: plainToClass(ContactModel, {
      id: null,
      nome: null,
      tipoContato: null,
      email: null,
      telefone: null,
      whatsapp: null,
      dataAniversario: null,
      flagAtivo: 1,
    }),
  };

  rules = {
    callType: [ValidationRules.required],
    nome: [ValidationRules.required],
    email: [ValidationRules.email],
    telefone: [ValidationRules.phone, ValidationRules.required],
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    prospect: {
      show: false,
    },
  };

  contactTypeOptions: ContactTypeModel[] = [];

  telephoneMaskActive = false;

  searchClient = '';

  searching = false;

  clientItems: ClientModel[] = [];

  searchProspect = '';

  prospectItems: ProspectModel[] = [];

  disableTelephone = false;

  disableWhatsapp = false;

  isWhatsAppValid = true;

  whatsAppCountryCallingCode!: string;

  private readonly debounce = 450;

  private debounceId!: number;

  onChangeWhatsApp(e): void {
    this.isWhatsAppValid = e.isValid;
    this.whatsAppCountryCallingCode = e.countryCallingCode;
  }

  @Watch('searchClient')
  watchSearchClient(value: string): void {
    clearTimeout(this.debounceId);
    if (!value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.clientItems = [];
      this.searching = false;
      return;
    }

    let unmaskedValue = value;

    if (value.match(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/)) {
      unmaskedValue = value.replace(/[^0-9]+/g, '');
    }

    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const result = await this.clientService.quickSearchIgsClient(unmaskedValue);
        this.clientItems = result.map((item) => {
          const newItem = item;
          newItem.nomeFantasia = newItem.nomeFantasia || newItem.nome;
          return newItem;
        });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  @Watch('searchProspect')
  watchSearchProspect(value: string): void {
    clearTimeout(this.debounceId);
    if (!value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.prospectItems = [];
      this.searching = false;
      return;
    }

    let unmaskedValue = value;

    if (value.match(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/)) {
      unmaskedValue = value.replace(/[^0-9]+/g, '');
    }

    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const result = await this.prospectService.quickSearch(unmaskedValue);

        this.prospectItems = result
          .filter((item) => !item.flagExcluido)
          .map((item) => {
            const newItem = item;
            newItem.fantasia = newItem.fantasia || newItem.razaoSocial || newItem.contato;
            return newItem;
          });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  async mounted(): Promise<void> {
    this.model.callType = this.call.tipo;

    const loader = this.setBusyLoader();
    try {
      const tasks = [this.loadSettings(), this.loadContactTypes()];

      if (this.call.contato?.id) {
        tasks.push(this.loadContact(this.call.contato.id));
      }

      if (this.call.tipo === ConversationTypeEnum.Client && this.call.cnpj) {
        tasks.push(this.loadClient(this.call.cnpj));
      }

      if (this.call.tipo === ConversationTypeEnum.Prospect && this.call.cnpj) {
        tasks.push(this.loadProspect(this.call.cnpj));
      }

      await Promise.all(tasks);

      if (!this.call.contato?.id || !this.model.contact.whatsapp) {
        this.model.contact.telefone = this.parsedPhoneNumber;
        setTimeout(() => {
          this.telephoneMaskActive = true;
        }, 150);
      }

      this.disableTelephone = !!this.model.contact?.telefone;
      this.disableWhatsapp = !!this.model.contact?.whatsapp;

      // If a client or prospect was provided, try to get a contact
      if (this.model.client) {
        this.clientItems = [this.model.client];
        await this.onSelectClient();
      }

      if (this.model.prospect) {
        this.prospectItems = [this.model.prospect];
        await this.onSelectProspect();
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSelectClient(): Promise<void> {
    if (this.model.client && this.model.contact.whatsapp) {
      const contact = await this.contactService.getContactByClientId(
        this.model.contact.whatsapp,
        this.model.client.cnpjCpf,
        ClientTypeEnum.Client,
        this.settings?.flagPermiteClientesSituacao99Crm360,
      );

      if (contact) {
        this.model.contact = contact;
        this.disableTelephone = !!this.model.contact?.telefone;
        this.disableWhatsapp = !!this.model.contact?.whatsapp;
      }
    }
  }

  async onSelectProspect(): Promise<void> {
    if (this.model.prospect && this.model.contact.whatsapp) {
      const contact = await this.contactService.getContactByClientId(
        this.model.contact.whatsapp,
        this.model.prospect.codProspect,
        ClientTypeEnum.Prospect,
        this.settings?.flagPermiteClientesSituacao99Crm360,
      );

      if (contact) {
        this.model.contact = contact;
        this.disableTelephone = !!this.model.contact?.telefone;
        this.disableWhatsapp = !!this.model.contact?.whatsapp;
      }
    }
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      if (!this.isWhatsAppValid) {
        this.$notify.error(`${this.$t('global.validations.phone', { fieldName: 'Whatsapp' })}`);
        return;
      }

      if (this.model.callType === ConversationTypeEnum.Client && !this.model.client?.id) {
        this.$notify.error(this.$t('crm.callCenterContactForm.messages.selectAClientToSaveContact'));
        return;
      }

      if (this.model.callType === ConversationTypeEnum.Prospect && !this.model.prospect?.codProspect) {
        this.$notify.error(this.$t('crm.callCenterContactForm.messages.selectAProspectToSaveContact'));
        return;
      }

      if (this.whatsAppCountryCallingCode) {
        this.model.contact.whatsapp = `+${this.whatsAppCountryCallingCode} ${this.model.contact.whatsapp}`;
      }

      const callSnapshot = cloneDeep(this.call);

      const loader = this.setBusyLoader();
      try {
        let cnpj = '';
        let idProspect: number | null = null;
        if (this.model.callType === ConversationTypeEnum.Prospect) {
          cnpj = this.model.prospect?.cnpj as string;
          idProspect = parseInt(this.model.prospect?.codProspect || '0', 10);
          this.model.client = null;
        } else {
          cnpj = this.model.client?.cnpjCpf as string;
          this.model.prospect = null;
        }

        this.model.contact.idProspect = idProspect as number;

        const contactModel = cloneDeep(this.model.contact);
        contactModel.cnpj = (cnpj || '').replace(/\D/g, '');

        let result: ContactModel;
        if (this.model.contact.id) {
          result = await this.contactService.update(contactModel);
        } else {
          result = await this.contactService.create(contactModel);
        }

        this.call.contato = result;
        this.call.tipo = this.model.callType;
        this.call.cnpj = cnpj;
        this.call.prospect = this.model.prospect as ProspectModel;
        this.call.cliente = this.model.client as ClientModel;

        const newCall = await this.callCenterCallService.attachContact(this.call);

        this.$notify.success(this.$t('crm.callCenterContactForm.successfullySave'));

        this.$emit('complete', newCall);
      } catch (err) {
        this.call = callSnapshot;

        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onCreateContact(): void {
    const { telefone, whatsapp } = this.model.contact;

    this.model.prospect = null;
    this.model.client = null;

    this.model.contact = plainToClass(ContactModel, {
      id: null,
      nome: null,
      tipoContato: null,
      email: null,
      telefone: null,
      whatsapp: whatsapp || telefone,
      dataAniversario: null,
      flagAtivo: 1,
    });

    this.disableTelephone = false;
    this.disableWhatsapp = !!this.model.contact?.whatsapp;
  }

  onCreateProspect(): void {
    this.dialogConfig.prospect.show = true;
  }

  onAfterSaveProspect(prospect: ProspectModel): void {
    this.prospectItems = [prospect];
    this.model.prospect = prospect;
  }

  onProspectDialogClose(): void {
    this.dialogConfig.prospect.show = false;
  }

  get isFormBlocked(): boolean {
    return !!this.model.contact?.id;
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get parsedPhoneNumber(): string {
    return this.call.numeroContato;
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.model.contact.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  private async loadContact(id: number): Promise<void> {
    const result = await this.contactService.getContact(id);
    const mappedObject = ObjectHelper.mapObjectValues<ContactModel>(result, {
      ignore: ['flagMethod', 'inclusaoUsuarioId', 'inclusaoData', 'alteracaoUsuarioId', 'alteracaoData', 'tipo'],
    });
    this.model.contact = plainToClass(ContactModel, mappedObject);
  }

  private async loadClient(clientId: string): Promise<void> {
    const client = await this.clientService.getIgsClient(clientId);
    if (client !== null) {
      client.nomeFantasia = client.nomeFantasia || client.nome;
    }

    this.model.client = client;
  }

  private async loadProspect(clientId: string): Promise<void> {
    this.model.prospect = await this.prospectService.getByCnpj(clientId);
  }

  private async loadSettings(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
  }

  private async loadContactTypes(): Promise<void> {
    this.contactTypeOptions = await this.contactService.getContactTypes();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.callCenterContactFormContainer,
      canCancel: false,
    });
  }
}
