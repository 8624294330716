var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"chatContactFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[(_vm.dialogConfig.existingContacts.show)?_c('CrmExistingContactsView',{attrs:{"client":_vm.clientToDuplicateContactsView,"is-from-prospect-form":false,"contactNumberType":"de whatsapp"},on:{"complete":function (e) {
        this$1.acceptedWhatsapp = this$1.whatsapp;
        _vm.toggleDialogExistingContacts();
        _vm.onSave();
      },"cancel":function($event){return _vm.onCancelSave()}},model:{value:(_vm.existingContacts),callback:function ($$v) {_vm.existingContacts=$$v},expression:"existingContacts"}}):_vm._e(),(_vm.dialogConfig.existingTelephones.show)?_c('CrmExistingContactsView',{attrs:{"client":_vm.clientToDuplicateContactsView,"contact-client-type":_vm.model.conversationType,"is-from-prospect-form":false,"contactNumberType":"de telefone"},on:{"complete":function (e) {
        this$1.telephoneAcceptedDuplicate = this$1.telephone;
        _vm.toggleDialogExistingTelephones();
      },"cancel":function($event){return _vm.onCancelSaveTelephone()}},model:{value:(_vm.existingTelephones),callback:function ($$v) {_vm.existingTelephones=$$v},expression:"existingTelephones"}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dialogConfig.existingContacts.show && !_vm.dialogConfig.existingTelephones.show),expression:"!dialogConfig.existingContacts.show && !dialogConfig.existingTelephones.show"}]},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.conversationType'),"rules":_vm.rules.conversationType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var rules = ref.rules;
return [_c('v-radio-group',{staticClass:"pt-0",attrs:{"row":"","rules":rules,"disabled":_vm.isFormBlocked || _vm.internalDisableForm,"hide-details":"auto"},model:{value:(_vm.model.conversationType),callback:function ($$v) {_vm.$set(_vm.model, "conversationType", $$v)},expression:"model.conversationType"}},[_c('v-radio',{attrs:{"label":_vm.$t('crm.chatContactForm.label.client'),"value":"CLIENT"}}),_c('v-radio',{attrs:{"label":_vm.$t('crm.chatContactForm.label.prospect'),"value":"PROSPECT"}})],1)]}}])})],1),(_vm.model.conversationType === 'CLIENT')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.client'),"rules":_vm.rules.client},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.clientItems,"loading":_vm.searching,"search-input":_vm.searchClient,"rules":rules,"disabled":_vm.isFormBlocked || _vm.internalDisableForm,"placeholder":_vm.$t('crm.chatContactForm.searchForClient'),"item-text":"cnpjCpf","no-filter":"","hide-no-data":"","return-object":"","hide-details":"auto","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:searchInput":function($event){_vm.searchClient=$event},"update:search-input":function($event){_vm.searchClient=$event},"change":_vm.onSelectClient},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nomeFantasia)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.cnpjCpfFilter(item.cnpjCpf))+" - "+_vm._s(item.nome))])],1)]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-truncate",domProps:{"textContent":_vm._s(item.nomeFantasia)}})]}}],null,true),model:{value:(_vm.model.client),callback:function ($$v) {_vm.$set(_vm.model, "client", $$v)},expression:"model.client"}})]}}],null,false,1320529463)})],1):_vm._e(),(_vm.model.conversationType === 'PROSPECT')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.prospect'),"rules":_vm.rules.prospect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.prospectItems,"loading":_vm.searching,"search-input":_vm.searchProspect,"rules":rules,"disabled":_vm.isFormBlocked || _vm.internalDisableForm,"placeholder":_vm.$t('crm.chatContactForm.searchOrCreateProspect'),"item-text":"cnpj","no-filter":"","hide-no-data":"","return-object":"","hide-details":"auto","prepend-inner-icon":"mdi-magnify","append-icon":""},on:{"update:searchInput":function($event){_vm.searchProspect=$event},"update:search-input":function($event){_vm.searchProspect=$event},"change":_vm.onSelectProspect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.fantasia)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.cnpjCpfFilter(item.cnpj))+" - "+_vm._s(item.razaoSocial)+" ")])],1)]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-truncate",domProps:{"textContent":_vm._s(item.razaoSocial)}})]}}],null,true),model:{value:(_vm.model.prospect),callback:function ($$v) {_vm.$set(_vm.model, "prospect", $$v)},expression:"model.prospect"}})]}}],null,false,1468246206)})],1):_vm._e(),_c('v-spacer'),(_vm.model.conversationType === 'PROSPECT' && _vm.model.prospect === null)?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.onCreateProspect}},[_c('span',[_vm._v(_vm._s(_vm.$t('crm.chatContactForm.createProspect')))])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.name'),"rules":_vm.rules.nome},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.contact.nome),callback:function ($$v) {_vm.$set(_vm.model.contact, "nome", $$v)},expression:"model.contact.nome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.email'),"rules":_vm.rules.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.contact.email),callback:function ($$v) {_vm.$set(_vm.model.contact, "email", $$v)},expression:"model.contact.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.contactType'),"rules":_vm.rules.tiposContato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.contactTypeOptions,"label":label,"rules":rules,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"","multiple":"","clearable":""},on:{"change":function($event){return _vm.onChangeTipoContato($event)}},model:{value:(_vm.model.contact.tiposContato),callback:function ($$v) {_vm.$set(_vm.model.contact, "tiposContato", $$v)},expression:"model.contact.tiposContato"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.birthdate'),"rules":_vm.rules.dataAniversario},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('DatePickerField',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.contact.dataAniversario),callback:function ($$v) {_vm.$set(_vm.model.contact, "dataAniversario", $$v)},expression:"model.contact.dataAniversario"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.chatContactForm.telephone'),"rules":_vm.rules.telefone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var label = ref.label;
      var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMaskActive && _vm.telephoneMask),expression:"telephoneMaskActive && telephoneMask"}],attrs:{"rules":rules,"label":label,"disabled":_vm.disableTelephone,"hide-details":"auto"},on:{"focus":function($event){_vm.telephoneMaskActive = true},"blur":_vm.onVerifyTelephone},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('vue-phone-number-input',{attrs:{"preferred-countries":_vm.preferredCountries,"show-code-on-list":true,"no-use-browser-locale":true,"fetch-country":false,"translations":_vm.translationsWhatsapp,"disabled":_vm.disableForm,"required":true,"color":_vm.$vuetify.theme.currentTheme.primary},on:{"update":function (e) { return _vm.onChangeWhatsApp(e); }},model:{value:(_vm.model.contact.whatsapp),callback:function ($$v) {_vm.$set(_vm.model.contact, "whatsapp", $$v)},expression:"model.contact.whatsapp"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('crm.chatContactForm.active'),"disabled":_vm.isFormBlocked || _vm.internalDisableForm,"value":0,"hide-details":"auto"},model:{value:(_vm.model.contact.flagAtivo),callback:function ($$v) {_vm.$set(_vm.model.contact, "flagAtivo", $$v)},expression:"model.contact.flagAtivo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onVerifyContact}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1),_c('ContentDialog',{attrs:{"title":_vm.$t('crm.chatContactForm.dialog.prospect.title'),"max-width":"800px"},on:{"after-close":_vm.onProspectDialogClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var parent = ref.parent;
return [_c('CrmChatProspectForm',{attrs:{"default-telephone":_vm.model.contact.whatsapp},on:{"cancel":parent.close,"complete":function($event){[_vm.onAfterSaveProspect($event), parent.close()]}},model:{value:(_vm.hasExistingContacts),callback:function ($$v) {_vm.hasExistingContacts=$$v},expression:"hasExistingContacts"}})]}}]),model:{value:(_vm.dialogConfig.prospect.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.prospect, "show", $$v)},expression:"dialogConfig.prospect.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }