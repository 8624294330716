export abstract class Translations {
  static getTranslationsWhatsApp(): unknown {
    return {
      countrySelectorLabel: 'Código',
      countrySelectorError: 'Erro na seleção',
      phoneNumberLabel: 'Whatsapp',
      example: 'Exemplo:',
    };
  }

  static getTranslations(): unknown {
    return {
      countrySelectorLabel: 'Código',
      countrySelectorError: 'Erro na seleção',
      phoneNumberLabel: 'Telefone/Celular',
      example: 'Exemplo:',
    };
  }
}
