var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"prospectFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[(_vm.dialogConfig.existingContacts.show)?_c('CrmExistingContactsView',{attrs:{"client":_vm.model,"is-from-prospect-form":false,"contactNumberType":"de telefone"},on:{"complete":function($event){return _vm.onSave()},"cancel":function($event){return _vm.onCancelSave()}},model:{value:(_vm.existingContacts),callback:function ($$v) {_vm.existingContacts=$$v},expression:"existingContacts"}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dialogConfig.existingContacts.show),expression:"!dialogConfig.existingContacts.show"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.cnpj'),"rules":_vm.rules.cnpj},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.cnpjCpfMask),expression:"cnpjCpfMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"change":function($event){return _vm.verificaCnpjExisteEmCliente()}},model:{value:(_vm.model.cnpj),callback:function ($$v) {_vm.$set(_vm.model, "cnpj", $$v)},expression:"model.cnpj"}})]}}])})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.name'),"rules":_vm.rules.fantasia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.fantasia),callback:function ($$v) {_vm.$set(_vm.model, "fantasia", $$v)},expression:"model.fantasia"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.legalName'),"rules":_vm.rules.razaoSocial},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.razaoSocial),callback:function ($$v) {_vm.$set(_vm.model, "razaoSocial", $$v)},expression:"model.razaoSocial"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.telephone'),"rules":_vm.rules.telefone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMaskActive && _vm.telephoneMask),expression:"telephoneMaskActive && telephoneMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.telephoneMaskActive = true}},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.contact'),"rules":_vm.rules.contato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.contato),callback:function ($$v) {_vm.$set(_vm.model, "contato", $$v)},expression:"model.contato"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onVerifyContact}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1),_c('ConfirmationDialog',{attrs:{"msg":_vm.dialogConfig.confirmation.message,"color":_vm.dialogConfig.confirmation.color,"textConfirm":_vm.dialogConfig.confirmation.textConfirm,"onlyWarning":_vm.dialogConfig.confirmation.onlyWarning},on:{"choice":_vm.dialogConfig.confirmation.onChoice},model:{value:(_vm.dialogConfig.confirmation.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.confirmation, "show", $$v)},expression:"dialogConfig.confirmation.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }